


















import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import {
  Interval,
  LookupWord,
  LookupWordType,
  SessionSlide,
  WordSegment,
} from "@/core/models";
import { HowToPopup } from "@/components/common";
import { InformationTooltip, WordSearch } from "@/components/common";

@Component({ components: { InformationTooltip, HowToPopup, WordSearch } })
export default class LookupWords extends Vue {
  @Getter("currentSession/zoom") zoom!: boolean;
  @Getter("currentSession/interval") interval!: Interval;
  @Getter("currentSession/words") allWords!: WordSegment[];
  @Getter("currentSession/lookupWords") lookupWords!: LookupWord[];
  @Getter("currentSession/currentSlide") currentSlide!: SessionSlide;

  get headline() {
    return this.$t("lookup.title");
  }

  get words() {
    if (!this.zoom) return this.lookupWords;

    const { start, end } = this.interval;
    const filteredWords = this.allWords.filter(
      x => x.offset >= start && x.offset <= end,
    );

    const result: LookupWord[] = [];
    for (const word of filteredWords) {
      const idx = result.findIndex(x => x.text === word.word.toLowerCase());
      if (idx === -1) {
        const type =
          this.lookupWords.find(
            x => x.text.toLowerCase() === word.word.toLowerCase(),
          )?.type || LookupWordType.default;
        result.push({ text: word.word.toLowerCase(), occurrences: 1, type });
      } else result[idx].occurrences++;
    }
    result.sort((a, b) => b.occurrences - a.occurrences);
    return result;
  }
}
