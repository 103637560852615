



























import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";
import ZoomControls from "./controls/ZoomControls.vue";
import { AudioPlayer, SessionVideoPlayer } from "@/components/common";
import BenchmarkSelect from "./controls/BenchmarkSelect.vue";
import DataPointsSelect from "./controls/DataPointsSelect.vue";

@Component({
  components: {
    BenchmarkSelect,
    DataPointsSelect,
    ZoomControls,
    AudioPlayer,
    SessionVideoPlayer,
  },
})
export default class SessionControls extends Vue {
  @Getter("currentSession/url") url!: string;
  @Getter("currentSession/videoUrl") videoUrl?: string;
  @Getter("currentSession/canZoom") canZoom!: boolean;
  @Getter("currentSession/canLiveZoom") canLiveZoom!: boolean;
}
