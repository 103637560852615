import { ChartData } from "chart.js";
import { ChartEntry } from "@/core/models/sessions";

// -------------
// Chart Data
// -------------
export const getDataFromChartEntry = (
  entries: ChartEntry[],
  backgroundColor: string,
): object => {
  let labels: number[] = [];
  let data: number[] = [];
  try {
    labels = entries.map((_, i) => i + 1);
    data = entries.map(entry => Math.round(entry.Value * 100) / 100);
  } catch (e) {
    labels = [];
    data = [];
  }

  return {
    labels,
    datasets: [
      {
        lineTension: 0.3,
        borderColor: backgroundColor,
        backgroundColor: backgroundColor,
        pointBackgroundColor: backgroundColor,
        pointBorderColor: "white",
        borderCapStyle: "round",
        data,
      },
    ],
  };
};

export const getPieChartData = (
  labels: string[],
  data: number[],
  backgroundColors: string[],
): ChartData => {
  return {
    labels,
    datasets: [
      {
        backgroundColor: backgroundColors,
        borderCapStyle: "round",
        hoverBorderWidth: 0,
        borderWidth: 0,
        data,
      },
    ],
  };
};

export const getPercentageChartData = (
  percentage: number,
  backgroundColors: string[],
): ChartData => {
  const data = [percentage, 100 - percentage];
  return {
    datasets: [
      {
        backgroundColor: backgroundColors,
        borderColor: "rgba(255, 255, 255, 1)",
        borderWidth: 1,
        hoverBorderWidth: 1,
        borderCapStyle: "round",
        data,
      },
    ],
  };
};

export const getSentimentChartData = (
  positive: number,
  negative: number,
  neutral: number,
): ChartData => {
  const data = [positive, negative, neutral];
  return {
    datasets: [
      {
        backgroundColor: ["green", "red", "grey"],
        borderColor: "rgba(255, 255, 255, 1)",
        borderWidth: 1,
        hoverBorderWidth: 1,
        borderCapStyle: "round",
        data,
      },
    ],
  };
};

export const getChartData = (
  labels: string[],
  data: number[],
  backgroundColor: string,
): ChartData => {
  return {
    labels,
    datasets: [
      {
        lineTension: 0.3,
        borderColor: backgroundColor,
        backgroundColor,
        pointBackgroundColor: backgroundColor,
        pointBorderColor: "white",
        borderCapStyle: "round",
        data,
      },
    ],
  };
};

export const getEmptyChartData = (): ChartData => ({
  labels: [],
  datasets: [
    {
      lineTension: 0.3,
      backgroundColor: "black",
      borderWidth: 0,
      borderCapStyle: "round",
      data: [],
    },
  ],
});

// -------------
// Helpers
// -------------
export const hexToRgba = (hex: string, alpha: number) => {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let cArr = hex.substring(1).split("");
    if (cArr.length == 3)
      cArr = [cArr[0], cArr[0], cArr[1], cArr[1], cArr[2], cArr[2]];

    const c = ("0x" + cArr.join("")) as any;
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      ", " +
      alpha +
      ")"
    );
  }
  throw new Error("Bad Hex");
};

export const getChangePercentage = (data: number[]): string => {
  const middle = data.length / 2;
  const firstHalf = data.slice(0, middle);
  const secondHalf = data.slice(middle, data.length);
  const firstHalfAvg =
    Math.round(
      (firstHalf.reduce((curr, total) => (total += curr), 0) /
        firstHalf.length) *
        1000,
    ) / 1000;
  const secondHalfAvg =
    Math.round(
      (secondHalf.reduce((curr, total) => (total += curr), 0) /
        secondHalf.length) *
        1000,
    ) / 1000;

  // Avoids Infinity
  //   if (firstHalfAvg == 0) firstHalfAvg = 0.1;
  //   if (secondHalfAvg == 0) secondHalfAvg = 0.1;

  let changePercentage = 0;
  let result = "";
  if (firstHalfAvg > secondHalfAvg) {
    changePercentage = Math.round((firstHalfAvg / secondHalfAvg) * 100) / 100;
    result = `-${changePercentage}%`;
    if (changePercentage == Infinity) result = "-&infin;";
  } else if (secondHalfAvg > firstHalfAvg) {
    changePercentage = Math.round((secondHalfAvg / firstHalfAvg) * 100) / 100;
    result = `+${changePercentage}%`;
    if (changePercentage == Infinity) result = "+&infin;";
  } else result = "0%";

  return result;
};

export const reduceData = (
  entries: ChartEntry[],
  max: number,
): ChartEntry[] => {
  if (entries.length <= 50 || max >= entries.length || max === 42)
    return entries;

  const len = entries.length - 1;
  const step = Math.round(len / max);
  const arr: ChartEntry[] = [];
  let index = 0;
  while (index <= len) {
    const element = entries[index];
    let total = 0;
    let count = step;
    total += entries
      .slice(index, index + step)
      .reduce((acc, curr) => acc + curr.Value, 0);
    index += step;

    if (index > len) {
      total += entries
        .slice(index, len)
        .reduce((acc, curr) => acc + curr.Value, 0);
      const countRest = len - index;
      if (countRest > 0) count += countRest;
    }

    arr.push(new ChartEntry(element.Timestamp, total / count));
  }
  return arr;
};
