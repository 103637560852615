













































































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";
import { SessionScores } from "@/core/models";

// todo translate
@Component
export default class WPMCard extends Vue {
  @Getter("currentSession/scores") scores!: SessionScores;

  get ititle() {
    return this.$t("sessionsPage.sessionsListSection.scoresCard.infoTitle");
  }
  get itext() {
    return this.$t("sessionsPage.sessionsListSection.scoresCard.infoText");
  }
  get headline() {
    return this.$t("sessionsPage.sessionsListSection.scoresCard.cardTitle");
  }
}
