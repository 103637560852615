






































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { SharingUser } from "@/core/store/managed";

@Component
export default class ManagedUsers extends Vue {
  @Getter("managed/active") active!: boolean;
  @Getter("managed/retrieved") retrieved!: boolean;
  @Getter("managed/usersLoading") loading!: boolean;
  @Getter("managed/sharingUsers") users!: SharingUser[];
  @Getter("managed/sessionsLoading") sessionsLoading!: boolean;
  @Action("managed/getUsers")
  getUsers!: () => Promise<void>;
  @Action("managed/getShared")
  getShared!: (e: string[]) => Promise<void>;

  usersDialog = false;
  selected: string[] = [];

  async viewSessions(email?: string) {
    await this.getShared(this.selected);
    if (this.selected.length == 1)
      this.$router.push(`/sessions/shared/${email}`);
    else this.$router.push("/sessions/shared");
  }

  async getUser(email: string) {
    this.$router.push(`/sessions/shared/${email}`);
  }

  @Watch("usersDialog")
  retryRequest() {
    if (!this.retrieved) this.getUsers();
  }

  mounted() {
    if (!this.retrieved) this.getUsers();
  }
}
