





















import Vue from "vue";
import { Getter, Action } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";
import { Interval, SessionSlide } from "@/core/models";

@Component
export default class ZoomControls extends Vue {
  @Getter("audio/time") time!: number;
  @Getter("audio/total") total!: number;
  @Getter("currentSession/zoom") zoom!: boolean;
  @Getter("currentSession/canZoom") canZoom!: boolean;
  @Getter("currentSession/interval") interval!: Interval;
  @Getter("currentSession/canLiveZoom") canLiveZoom!: boolean;
  @Getter("currentSession/currentSlide") slide?: SessionSlide;
  @Action("currentSession/setZoom") setZoom!: (b: boolean) => void;
  @Action("currentSession/setZoomInterval")
  setZoomInterval!: (i: Interval) => void;

  slideZoom = false;
  liveZoom = false;
  toggleSlideZoom(val: boolean) {
    this.liveZoom = false;
    this.slideZoom = val;
    this.setZoom(val);
    this.setSlideInterval();
  }
  toggleLiveZoom(val: boolean) {
    this.slideZoom = false;
    this.liveZoom = val;
    this.setZoom(val);
    this.setLiveInterval();
  }

  @Watch("time")
  timeupdate() {
    if (this.slideZoom && this.slide) this.setSlideInterval();
    else this.setLiveInterval();
  }

  @Watch("zoom")
  zoomChanged() {
    if (this.slideZoom) this.setSlideInterval();
    else this.setLiveInterval();
  }

  setSlideInterval() {
    if (!this.slide) return;
    const start = this.slide.offset;
    const end = this.slide.offset + this.slide.duration;
    this.setZoomInterval({ start, end });
  }
  setLiveInterval() {
    const timems = this.time * 1000;
    const totalms = this.total * 1000;
    const minms = 60 * 1000;
    const onethird = 20 * 1000;
    const twothirds = 40 * 1000;

    if (this.time <= 20) {
      this.setZoomInterval({ start: 0, end: minms });
      return;
    } else if (this.time >= this.total - 40) {
      this.setZoomInterval({
        start: totalms - minms,
        end: totalms,
      });
      return;
    }

    const { start, end } = this.interval;
    if (timems < start + onethird) {
      const newStart = start - onethird <= 0 ? 0 : start - onethird;
      this.setZoomInterval({ start: newStart, end: newStart + minms });
      return;
    }

    if (timems < start + twothirds) return;
    const newStart = start + onethird;
    const newEnd = end + onethird;
    if (newEnd > totalms)
      this.setZoomInterval({ start: newStart, end: totalms });
    else this.setZoomInterval({ start: newStart, end: newEnd });
  }
}
